<template>
    <div class="admin-content-manager">
      <div class="header">
        <h1 class="title">Content Management</h1>
        <p class="subtitle">Curate and manage trending topics across platforms</p>
      </div>

      <div class="actions-bar">
        <button @click="showAddForm = true" class="action-btn add-btn">
          <span class="icon">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </span>
          Add New Content
        </button>
      </div>

      <div class="platform-filters">
        <button
          v-for="platform in platforms"
          :key="platform"
          :class="['platform-btn', { active: selectedPlatform === platform }]"
          @click="selectedPlatform = platform"
        >
          <img :src="getPlatformIcon(platform)" :alt="platform" class="platform-icon" />
          {{ platform }}
        </button>
      </div>

      <div class="content-list">
        <draggable
          v-model="filteredContent"
          handle=".drag-handle"
          @end="handleDragEnd"
        >
          <div
            v-for="item in filteredContent"
            :key="item.id"
            class="content-item"
          >
            <div class="drag-handle">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <circle cx="9" cy="12" r="1"></circle>
                <circle cx="9" cy="5" r="1"></circle>
                <circle cx="9" cy="19" r="1"></circle>
                <circle cx="15" cy="12" r="1"></circle>
                <circle cx="15" cy="5" r="1"></circle>
                <circle cx="15" cy="19" r="1"></circle>
              </svg>
            </div>

            <div class="content-details">
              <div class="text">{{ item.text }}</div>
              <div class="metadata">
                <span class="platform-badge" :class="item.platform">
                  {{ item.platform }}
                </span>
                <span class="time">{{ formatDate(item.created_at) }}</span>
              </div>
            </div>

            <div class="actions">
              <button @click="editItem(item)" class="icon-btn edit-btn" title="Edit">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </button>
              <button @click="deleteItem(item.id)" class="icon-btn delete-btn" title="Delete">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M3 6h18"></path>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                </svg>
              </button>
            </div>
          </div>
        </draggable>
      </div>

      <!-- Add/Edit Modal -->
      <div v-if="showAddForm || editingItem" @close="closeModal" class="content-modal">
        <div class="modal-content">
          <h2 class="modal-title">{{ editingItem ? 'Edit' : 'Add' }} Content</h2>
          <form @submit.prevent="saveContent" class="content-form">
            <div class="form-group">
              <label>Platform</label>
              <select v-model="formData.platform" required>
                <option v-for="p in platforms" :key="p" :value="p">
                  {{ p }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Content Text</label>
              <textarea
                v-model="formData.text"
                required
                rows="4"
                placeholder="Enter content text..."
              ></textarea>
            </div>

            <div class="form-group">
              <label>Link (optional)</label>
              <input
                type="url"
                v-model="formData.link"
                placeholder="https://..."
              >
            </div>

            <div class="form-actions">
              <button type="button" @click="closeModal" class="btn cancel-btn">
                Cancel
              </button>
              <button type="submit" class="btn save-btn">
                {{ editingItem ? 'Update' : 'Add' }} Content
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
<script>
import draggable from 'vuedraggable';
import API from '@/api';

export default {
  name: 'AdminContentManager',
  components: { draggable },

  data() {
    return {
      content: [],
      selectedPlatform: 'linkedin',
      showAddForm: false,
      editingItem: null,
      platforms: ['linkedin', 'youtube', 'google', 'reddit', 'arxiv', 'techcrunch'],
      formData: {
        platform: 'linkedin',
        text: '',
        link: ''
      }
    };
  },

  computed: {
    filteredContent: function() {
    return this.content
      .filter(item => item.platform === this.selectedPlatform)
      .sort((a, b) => a.position - b.position);
  }
},

  methods: {
    handleDragEnd(evt) {
        const { oldIndex, newIndex } = evt;
        if (oldIndex !== newIndex) {
        this.updatePositions({ oldIndex, newIndex });
        }
    },
    getPlatformIcon(platform) {
      const icons = {
        linkedin: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z'/></svg>`,
        youtube: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z'/></svg>`,
        google: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z'/></svg>`,
        reddit: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z'/></svg>`,
        arxiv: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 15H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v11c0 .55-.45 1-1 1zm-1-4h-3v3h-2v-3h-3v-2h3V9h2v3h3v2z'/></svg>`,
        techcrunch: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2394A3B8'><path d='M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5'/></svg>`
      };
      return icons[platform] || '';
    },

    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },

    async fetchContent() {
      try {
        const response = await API.get('/admin/curated_content');
        this.content = response.data;
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    },

    async saveContent() {
      try {
        if (this.editingItem) {
          await API.put(`/admin/curated_content/${this.editingItem.id}`, this.formData);
        } else {
          await API.post('/admin/curated_content', this.formData);
        }
        await this.fetchContent();
        this.closeModal();
      } catch (error) {
        console.error('Error saving content:', error);
      }
    },

    async deleteItem(id) {
      if (confirm('Are you sure you want to delete this item?')) {
        try {
          await API.delete(`/admin/curated_content/${id}`);
          await this.fetchContent();
        } catch (error) {
          console.error('Error deleting content:', error);
        }
      }
    },

    async updatePositions({ oldIndex, newIndex }) {
        const items = [...this.filteredContent]; // Create a copy of the array
        const item = items[oldIndex];

        // Calculate new positions for all items
        const reorderedItems = items.map((item, index) => ({
            ...item,
            position: index * 10  // Give some space between positions (10, 20, 30, etc.)
        }));

        try {
            // Update the moved item's position
            await API.put(`/admin/curated_content/${item.id}`, {
            ...item,
            position: reorderedItems[newIndex].position
            });

            // Optionally, update all affected items' positions
            for (let i = Math.min(oldIndex, newIndex); i <= Math.max(oldIndex, newIndex); i++) {
            if (i !== newIndex) { // Skip the already updated item
                await API.put(`/admin/curated_content/${items[i].id}`, {
                ...items[i],
                position: reorderedItems[i].position
                });
            }
            }

            await this.fetchContent();
        } catch (error) {
            console.error('Error updating positions:', error);
        }
    },

    editItem(item) {
      this.editingItem = item;
      this.formData = { ...item };
      this.showAddForm = true;
    },

    closeModal() {
      this.showAddForm = false;
      this.editingItem = null;
      this.formData = {
        platform: 'linkedin',
        text: '',
        link: ''
      };
    }
  },

  created() {
    this.fetchContent();
  }
};
</script>

<style scoped>
.admin-content-manager {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  margin-bottom: 2rem;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-bottom: 0.5rem;
  background: linear-gradient(to right, #94a3b8, #e1e5ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subtitle {
  font-size: 1.1rem;
  color: #64748b;
}

.actions-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.add-btn {
  background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0.2));
  color: #e2e8f0;
}

.platform-filters {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.platform-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  border: 1px solid rgba(203, 213, 225, 0.4);
  background: transparent;
  color: #94a3b8;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
}

.platform-btn.active {
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.2));
  border-color: rgba(255,255,255,0.1);
  color: #e2e8f0;
}

.platform-icon {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.content-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.content-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.1));
  border-radius: 12px;
  border: 1px solid rgba(203, 213, 225, 0.1);
  transition: all 0.2s ease;
}

.content-item:hover {
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.15));
  transform: translateY(-1px);
}

.drag-handle {
  cursor: move;
  color: #64748b;
  padding: 0.5rem;
}

.content-details {
  flex: 1;
}

.text {
  color: #e2e8f0;
  margin-bottom: 0.5rem;
}

.metadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
}

.platform-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  background: rgba(255,255,255,0.1);
  color: #94a3b8;
}

.time {
  color: #64748b;
}

.actions {
  display: flex;
  gap: 0.5rem;
}

.icon-btn {
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  background: transparent;
  color: #94a3b8;
  cursor: pointer;
  transition: all 0.2s ease;
}

.icon-btn:hover {
  background: rgba(255,255,255,0.1);
  color: #e2e8f0;
}

.edit-btn:hover {
  color: #0ea5e9;
}

.delete-btn:hover {
  color: #ef4444;
}

/* Modal Styles */
.content-modal {
  background: linear-gradient(to right, rgba(255,255,255,0.05), rgba(255,255,255,0.1));
  backdrop-filter: blur(12px);
  border-radius: 16px;
  border: 1px solid rgba(255,255,255,0.1);
  padding: 2rem;
}

.modal-title {
  font-size: 1.5rem;
  color: #e2e8f0;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: #94a3b8;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid rgba(203, 213, 225, 0.2);
  background: rgba(255,255,255,0.05);
  color: #e2e8f0;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-btn {
  background: rgba(255,255,255,0.1);
  color: #94a3b8;
}

.save-btn {
  background: linear-gradient(to right, #94a3b8, #64748b);
  color: white;
}

.cancel-btn:hover {
  background: rgba(255,255,255,0.15);
}

.save-btn:hover {
  transform: translateY(-1px);
}

@media (max-width: 768px) {
  .admin-content-manager {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .content-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .actions {
    align-self: flex-end;
  }

  .platform-filters {
    gap: 0.5rem;
  }

  .platform-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
  }
}
</style>
